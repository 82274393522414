export const environment = {
  WS_GRAPHQL_ENDPOINT: 'wss://api.np.rebac.eu/graphql',
  HTTP_GRAPHQL_ENDPOINT: 'https://api.np.rebac.eu/graphql',
  AUTH0_GRAPHQL_AUDIENCE: 'https://graphql.np.rebac.de',
  AUTH0_DOMAIN: 'rebac-np.eu.auth0.com',
  AUTH0_CLIENT_ID: 'RsW89O6yNbtXWW9A88emgq6iHum5Fpjy',
  ASSET_URL:
    'https://rebac-assets-np.s3.eu-central-1.amazonaws.com/rebac-frontend/assets',
  STAGE: 'np',
  HOST: 'np.rebac.eu',
};
